import '@asseinfo/react-kanban/dist/styles.css';
import dayjs from "dayjs";
import { Button, ControlLabel, DateRangePicker, Icon, Loader, Notification, Panel, Placeholder, Toggle, Tooltip, Whisper } from "rsuite";
import { io } from "../../utilities/io";
//@ts-ignore
import Board, { moveCard } from "@asseinfo/react-kanban";
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CheckTreePicker } from '../../components/formfields/CheckTreePicker';
import axios from "../../utilities/axios";
import KanbanCard from './KanbanCard';
import { UserContext } from '../../components/authProvider/main';
import { locale } from '../../utilities/locale/DateRangePicker';
import { Permission } from '../../components/Permission';
import { KanbanSubFilter } from './KanbanSubFilter';

export class KanbanPage extends React.Component<any, {}> {
    static contextType = UserContext;

    public state: any = {
        initialData:[],
        data: [],
        steps: [],
        projects: [],
        filter: [],
        isLoading: true,
        isLoadingKanban: false,
        isDrawerOpen: false,
        viewCard: null,
        receiver: false
    };

    componentDidMount(): void {
        const params = new URLSearchParams(this.props.location.search);
        const projectId = params.get("project_id");
        const showExclude = params.get("show_exclude") ? params.get("show_exclude") : false;

        const startDate = dayjs().subtract(30, 'day').toDate();
        const endDate = dayjs().toDate();

        this.setState({
            filter: {
                ...this.state.filter,
                date: [startDate, endDate],
                projects: projectId ? [Number(projectId)] : [],
                show_exclude: showExclude
            }
        }, () => {
            this.getData();
        });

        this.getStepsData();

        if (this.state.data?.length <= 0) {
            io.on("kanban:receiver", async (data: any) => {
                if (data.company_id === this.props.companyId) {
                    this.setState({ receiver: dayjs().unix() });
                    this.getData();
                }
            });
        }

        io.on(`kanban:error`, (error) => {
            Notification.warning({ title: "Aviso!", description: error.message, duration: 5000 });
            this.getData();
        });
    }

    componentWillUnmount(): void {
        io.removeAllListeners("kanban:receiver");
        io.removeAllListeners("kanban:error");
    }

    // componentDidUpdate(): void {
    //     if (this.state.data?.length <= 0) {
    //         io.on("kanban:receiver", async (data: any) => {
    //             if (data.company_id === this.props.companyId) {
    //                 this.setState({ receiver: dayjs().unix() });
    //                 this.getData();
    //             }
    //         });
    //     }
    // }

    getData = () => {
        // if (filter?.steps?.length > 0) {
        this.setState({ isLoadingKanban: true });
        axios.post(`/api/v1/kanban-get`, {
            params: {
                ...this.state.filter
            }
        }).then((res: any) => {
            this.setState({ data: res.data, initialData: res.data });
        }).finally(() => {
            this.setState({ isLoading: false, isLoadingKanban: false });
        });
        // }
    }

    getStepsData = () => {
        axios.get("/api/v1/steps/select").then((res: any) => {
            const items = res.data.items.map((item: any) => item.value);
            this.setState({ steps: items });
        }).finally(() => {

        });
    }

    moveCardFn = async (currentCard: any, from: any, to: any) => {
        if (from?.fromColumnId === to?.toColumnId) {
            return
        }
        // console.log({from, to})
        // console.log({ currentCard });
        if (!currentCard.envolved_users?.includes(this.context?.data?.user_id) && ![1, 2].includes(this.context?.data?.user_fk_role_id)) {
            return Notification.warning({ title: "Aviso", description: "Não é possivel mover! Você não está envolvido nessa fase!" });
        }

        // if ((from.fromColumnId === 4 && to.toColumnId !== 1) || (from.fromColumnId === 1 && to.toColumnId === 4)) {
        //     return Notification.warning({ title: "Aviso", description: "Não é possivel mover!" });
        // }
        io.emit("kanban:emit", { currentCard, from, to });
        const newData = moveCard(this.state.data, from, to);
        this.setState({ data: newData });
    }

    selectFilter = (field: any, value: any) => {
        // this.setState({ filter: { ...this.state.filter, [field]: value } }, this.getData)
        this.setState({ filter: { ...this.state.filter, [field]: value } }, () => {
            const params = new URLSearchParams(this.props.location.search);
            if (field === "show_exclude") {
                if (value) {
                    params.set("show_exclude", "true");
                } else {
                    params.delete("show_exclude");
                }
                this.props.history.replace({ search: params.toString() });
            }
            this.getData();
        });
    }

    renderKanbanCard = () => {
        return (
            <KanbanCard
                item={this.state.viewCard}
                open={this.state.isDrawerOpen}
                onClose={() => this.setState({ isDrawerOpen: false })}
            />
        )
    }

    render(): React.ReactNode {
        const { data, filter, isLoading, isLoadingKanban } = this.state;
        const kanbanData = { ...data };
        
        return (
            <>
                <div style={{ marginBottom: 15 }}>
                    {/* {JSON.stringify(filter)} */}
                    <div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
                        {this.props.location.search ? (
                            filter.projects && (
                                <div style={{ width: "40%" }}>
                                    <ControlLabel>Projetos</ControlLabel>
                                    <CheckTreePicker
                                        name="projects"
                                        api={{
                                            method: "GET",
                                            url: `/api/v1/projects/select?sortBy=project_id&sortType=desc&filtered_by_envolved&show_exclude=${filter.show_exclude ?? false}`,
                                            uses: [
                                                {
                                                    type: "value",
                                                    field: "project_id",
                                                    as: "sortBy"
                                                },
                                                {
                                                    type: "value",
                                                    field: "desc",
                                                    as: "sortType"
                                                }
                                            ]
                                        }}
                                        displayLabel="projects.project_code + ' - ' + projects.project_description"
                                        value={filter.projects}
                                        onChange={(value: any) => this.selectFilter("projects", value)}
                                        style={{ width: "100%" }}
                                    />
                                </div>
                            )
                        ) : (
                            <div style={{ width: "40%" }}>
                                <ControlLabel>Projetos</ControlLabel>
                                <CheckTreePicker
                                    name="projects"
                                    api={{
                                        method: "GET",
                                        url: `/api/v1/projects/select?sortBy=project_id&sortType=desc&filtered_by_envolved${filter.show_exclude ? '&show_exclude' : ''}`,
                                    }}
                                    displayLabel="projects.project_code + ' - ' + projects.project_description"
                                    value={filter.projects}
                                    onChange={(value: any) => this.selectFilter("projects", value)}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        )}
                        <div style={{ width: "40%", marginLeft: 6 }}>
                            <ControlLabel>Fases</ControlLabel>
                            <CheckTreePicker
                                name="steps"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/steps/select?${filter?.projects?.length > 0 ? `project_id=[${filter.projects}]&project_id_by_step=true` : ''}`,
                                }}
                                value={filter.steps}
                                onChange={(value: any) => this.selectFilter("steps", value)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Exibir Arquivados?</ControlLabel>
                            <Toggle
                                defaultChecked={filter.show_exclude ?? false}
                                checked={filter.show_exclude ?? false}
                                onChange={(value: boolean) => this.selectFilter("show_exclude", value)}
                                style={{ width: "100%" }}
                            ></Toggle>
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Data Inicial/Final da Criação Projeto</ControlLabel>
                            <DateRangePicker
                                preventOverflow
                                locale={locale}
                                onClean={() => this.selectFilter("date", null)}
                                placement="bottomEnd"
                                format="DD/MM/YYYY"
                                value={this.state.filter?.date ?? undefined}
                                onChange={(value: any) => this.selectFilter("date", value)}
                            />
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 10 }}>
                        <Permission name="projects-add">
                            <Button
                                style={{ width: 200 }}
                                size="md"
                                color="green"
                                onClick={() => this.props.history.push(`/dashboard/project/projects/add`)}
                            >
                                <Icon icon="plus" /> &nbsp; Adicionar Projeto
                            </Button>
                        </Permission>

                        <Button
                            style={{ width: 125 }}
                            size="md"
                            color="blue"
                            onClick={() => this.getData()}
                        >
                            <Icon icon="refresh" /> &nbsp; Atualizar
                        </Button>
                    </div>
                </div>

                <div>
                    <KanbanSubFilter originalData={this.state.initialData} changeData={(newData:any)=> this.setState({data:newData})} />
                </div>

                {
                    isLoading && (
                        <div style={{ width: "100%", marginBottom: 15 }}>
                            <Placeholder.Paragraph rows={12} />
                        </div>
                    )
                }

                {
                    !isLoading && kanbanData && (
                        <>
                            <Panel bordered bodyFill>
                                {isLoadingKanban && (
                                    <Loader backdrop content="Carregando..." size="lg" />
                                )}
                                <Board
                                    style={{ width: "100%" }}
                                    disableColumnDrag={true}
                                    onCardDragEnd={this.moveCardFn}
                                    // initialBoard={data}
                                    renderColumnHeader={(column: any) =>
                                        <div style={{
                                            padding: '10px',
                                            backgroundColor: column.color,
                                            // position: 'sticky',
                                            // top: 0,
                                            // zIndex: 9999,
                                            borderBottom: '2px solid #ddd'
                                        }}>
                                            <p style={{ margin: 0, color: "#FFF" }}>{column.title}</p>
                                        </div>
                                    }
                                    renderCard={(item: any, index: number) => {
                                        return (<div className='kanbanAdjustment'>
                                            <div className="card-a" style={{ background: "white" }}>
                                                <div className="card-header-a">
                                                    <h6 className="card-title-a">
                                                        <Link
                                                            style={{ color: "#FFF" }}
                                                            key={index}
                                                            target={"_blank"}
                                                            to={`/dashboard/project/projects/${item?.project_id}`}
                                                        >
                                                            {item?.project_code}
                                                        </Link>
                                                        {item?.envolved_users?.includes(this.context?.data?.user_id) ? (
                                                            <>
                                                                {item?.chat_message_fk_situation_chat_id && (
                                                                    <Whisper trigger="hover" placement="top" speaker={<Tooltip placement="bottom">Chat da Fase</Tooltip>}>
                                                                        <Button
                                                                            appearance="primary"
                                                                            color="blue"
                                                                            size='sm'
                                                                            style={{ float: "right", marginTop: -10 }}
                                                                            onClick={() => this.setState({ viewCard: item, isDrawerOpen: true })}>
                                                                            <i className="fas fa-comments"></i>
                                                                        </Button>
                                                                    </Whisper>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <></>
                                                        )}
                                                    </h6>
                                                </div>
                                                <div className='card-body-a'>
                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                        <strong>Cliente</strong><br />
                                                        <Link
                                                            key={index}
                                                            target={"_blank"}
                                                            to={`/dashboard/registrations/people/view/${item?.project_client_fk_person_id}`}
                                                        >
                                                            {item?.cliente}
                                                            <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                        </Link>
                                                    </p>
                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                        <strong>Descrição</strong><br />
                                                        <Link
                                                            key={index}
                                                            target={"_blank"}
                                                            to={`/dashboard/project/projects/view/${item?.project_product_fk_project_id}`}
                                                        >
                                                            {item?.title} - {item?.product_name}
                                                            <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                        </Link>
                                                    </p>
                                                    <p className='break-word' style={{ marginBottom: -5 }}>
                                                        <strong>Fase</strong><br />
                                                        {item?.envolved_users?.includes(this.context?.data?.user_id) ? (
                                                            <Link
                                                                key={index}
                                                                target={"_blank"}
                                                                to={`/dashboard/project/chats/${item?.project_id}/${item?.project_product_id}/${item?.chat_message_fk_ppgstf_id}`}
                                                            >
                                                                {item.step_name}
                                                                <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                            </Link>
                                                        ) : (
                                                            <>
                                                                {item.step_name}
                                                            </>
                                                        )}
                                                    </p>
                                                    {Array.isArray(item?.envolved_people) && item?.envolved_people?.length > 0 && (
                                                        <p className='break-word'>
                                                            <strong>Pessoas por Fase</strong><br />
                                                            {item.envolved_people.map((people: any) => (
                                                                <>
                                                                    {people.name}
                                                                    <br />
                                                                </>
                                                            ))}
                                                        </p>
                                                    )}
                                                    {item?.situation_chat_id !== 3 && (
                                                        <p className='break-word'>
                                                            <strong>Prazo</strong><br />
                                                            {item?.project_deadline_step == 0 ? (
                                                               <>
                                                                    Hoje
                                                               </> 
                                                            ) : item?.project_deadline_step >= 0 ? (
                                                                <>
                                                                    {item?.project_deadline_step} Dias
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {Math.abs(item?.project_deadline_step)} Dias em atraso
                                                                </>
                                                            )} 
                                                        </p>
                                                    )}
                                                    {/* <Divider /> */}
                                                </div>
                                                {/* <div className="card-footer-a">
                                                    Criado em: {dayjs(item?.project_created_at).format("DD/MM/YYYY HH:mm")}
                                                </div> */}
                                            </div>
                                        </div>);
                                    }}
                                >
                                    {kanbanData}
                                </Board>
                                {this.renderKanbanCard()}
                            </Panel>
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(KanbanPage);
