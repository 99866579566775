import React from "react";
import { Button, CheckPicker, Panel, SelectPicker } from "rsuite";
import axios from "../../utilities/axios";
import { CheckTreePicker } from "../../components/formfields/CheckTreePicker";
import _ from "lodash";

export class KanbanSubFilter extends React.Component<any, {}> {
    public state: any = {
        filterTypes: [{ value: "products", label: "ITEM" }, { value: "people1", label: "CLIENTE" }, { value: "users", label: "USUÁRIOS ENVOLVIDOS" }, { value: "people", label: "PESSOAS POR FASE" }],
        filterType: null,
        filterValues: [],
        originalData: this.props.originalData,
    }
    // loadSelectData = (type: any) => {
    //     axios.get(`/api/v1/${type}/select?limit=9999`).then((res) => {
    //         this.setState({ filterSelectData: res.data.items })
    //     })
    // }
    changeFilterType = (value: any = null) => {
        this.setState({ filterType: value })
    }

    filtered = async (data: any, field: any) => {
        if (this.state.filterValues.length === 0) {
            return data
        }
        if (data?.kanbans) {
            let kanbanKeys = Object.keys(data?.kanbans)
            await Promise.all(
                _.map(kanbanKeys, (item) => {
                    _.map(data?.kanbans[item].columns, (column) => {

                        const filteredCards = _.filter(column.cards, (card) => {

                            const filteredCardValues = Array.isArray(card[field])
                                ? card[field]
                                : [card[field]].filter((value) => value != null);

                            if (field === "envolved_people" && Array.isArray(card[field])) {
                                return card[field].some((value: any) => this.state.filterValues.includes(value.id))
                            }

                            return _.intersection(filteredCardValues, this.state.filterValues).length > 0
                        });
                        column.title = (column.title).replace(/(.+?\[\s*)(\d+)(\s*\])/, `$1${filteredCards?.length}$3`);
                        column.cards = filteredCards
                        return column
                    })
                })

            )
            return data

        } else if (data?.columns) {
            await Promise.all(
                _.map(data.columns, (column) => {
                    const filteredCards = _.filter(column.cards, (card) => {

                        const filteredCardValues = Array.isArray(card[field])
                            ? card[field]
                            : [card[field]].filter((value) => value != null);

                        if (field === "envolved_people" && Array.isArray(card[field])) {
                            return card[field].some((value: any) => this.state.filterValues.includes(value.id))
                        }

                        return _.intersection(filteredCardValues, this.state.filterValues).length > 0
                    });
                    column.title = (column.title).replace(/(.+?\[\s*)(\d+)(\s*\])/, `$1${filteredCards?.length}$3`);
                    column.cards = filteredCards
                    return column
                })
            )
            return data
        }
    }

    runFilter = () => {
        const newData = _.cloneDeep(this.props.originalData)
        switch (this.state.filterType) {
            case "products":
                this.filtered(newData, "project_product_fk_product_id")
                break;
            case "users":
                this.filtered(newData, "envolved_users")
                break;
            case "people":
                this.filtered(newData, "envolved_people")
                break;
            case "people1":
                this.filtered(newData, "project_client_fk_person_id")
                break;
        }
        this.props.changeData(newData)

    }

    cleanFilter = () => {
        this.setState({ filterType: null, filterValues: [] })
        this.props.changeData(this.props.originalData)
    }


    render() {
        return (<>
            <Panel style={{ background: "#ddd" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 5 }}>
                    <SelectPicker placeholder="Selecione um SubFiltro..." style={{ minWidth: 280 }} data={this.state.filterTypes} value={this.state.filterType} onChange={(value) => {
                        this.changeFilterType(value)
                    }} />
                    {this.state.filterType ? (
                        <CheckTreePicker
                            onClean={() => this.setState({ filterValues: [] })}
                            onChange={(value: any) => this.setState({ filterValues: value })}
                            api={{
                                url: `api/v1/${(this.state.filterType)?.replace(/[^a-zA-Z]/g, '')}/select`
                            }} placeholder="Selecione..." style={{ minWidth: 500, maxWidth: 600 }} data={[]} />

                    ) : (<CheckPicker placeholder="Selecione..." style={{ minWidth: 500 }} data={[]} disabled />)}
                    <Button appearance="primary" color="blue" onClick={() => this.runFilter()}><i className="fas fa-fw fa-search"></i> Filtrar</Button>
                    <Button appearance="primary" color="violet" onClick={() => this.cleanFilter()}><i className="fas fa-fw fa-filter"></i>  Limpar</Button>

                </div>
            </Panel>
        </>)
    }
}