//IMPORTAR O COMPONENTE
import { CreateLambdaComplement } from "./CreateLambdaComplement";
import { CreateTablesComplement } from "./CreateTablesComplement";
import { TableComplement } from "./TableComplement";
import { TableGridLambda } from "./TableGridLambda";
import { ValidatePage } from './ValidatePage'
import { TableNewDownloader } from './TableNewDownloader'
import { ChatUserComponent } from "./chatUsers/ChatUserComponent";
import { ProjectProductFlowCustom } from "./ProjectProductFlowCustom";
import { ProjectProductGroupStepTableFlowsCustom } from "./ProjectProductGroupStepTableFlowsCustom";
import { CheckTreePickerUsersCustom } from "./CheckTreePickerUsersCustom";
import { DefinitionNotificationCustom } from "./DefinitionNotificationCustom";
import { ProjectMedia } from "./projectMedias/ProjectMedia";
import { CheckTreePickerPeopleCustom } from "./CheckTreePickerPeopleCustom";

export default {
    CreateLambdaComplement,
    CreateTablesComplement,
    TableComplement,
    TableGridLambda,
    ValidatePage,
    TableNewDownloader,
    ChatUserComponent,
    ProjectProductFlowCustom,
    ProjectProductGroupStepTableFlowsCustom,
    CheckTreePickerUsersCustom,
    DefinitionNotificationCustom,
    ProjectMedia,
    CheckTreePickerPeopleCustom
    // EXPORTAR O COMPONENTE
};
