import React from "react";
import { Button, CheckPicker, Divider, Input, Loader, Nav, Panel, SelectPicker, Toggle } from "rsuite";
import { AxiosSelectPicker } from "../../../components/formfields/AxiosSelectPicker";
import _ from "lodash";
import axios from "../../../utilities/axios";

export class IntegrationMbmConfigPage extends React.Component<any, {}> {
    public state: any = {
        values: {
            ...this.props.values?.MBM,
            "params": {
                "stockbuy": {
                    "importTypeProduct": null,
                    "fixedItem": false,
                    "fixedClient": false,
                    "defaultProduct": null,
                    "defaultClient": null,
                    "caractItem": false,
                    "caractItemSelected": null,
                },
                "feedstock": {
                    "importTypeProduct": null,
                    "fixedItem": false,
                    "fixedClient": false,
                    "defaultProduct": null,
                    "defaultClient": null,
                    "caractItem": false,
                    "caractItemSelected": null,
                },
                "stock": {
                    "importTypeProduct": null,
                    "fixedItem": false,
                    "fixedClient": false,
                    "defaultProduct": null,
                    "defaultClient": null,
                    "caractItem": false,
                    "caractItemSelected": null,
                },
                "pedvenda": {
                    "importTypeProduct": null,
                    "fixedItem": false,
                    "fixedClient": false,
                    "defaultProduct": null,
                    "defaultClient": null,
                    "caractItem": false,
                    "caractItemSelected": null,
                },
                ...this.props.values?.MBM?.params
            }
        },
        pedvenda: {
            selectItemOptions: [{ value: 1, label: "ITEM A ITEM" }, { value: 2, label: "ITEM FIXO" }],
            selectCaractOption: []
        },
        stock: {
            selectItemOptions: [{ value: 1, label: "ITEM A ITEM" }, { value: 2, label: "ITEM FIXO" }],
            selectCaractOption: []
        },
        stockbuy: {
            selectItemOptions: [{ value: 1, label: "ITEM A ITEM" }, { value: 2, label: "ITEM FIXO" }],
            selectCaractOption: []
        },
        feedstock: {
            selectItemOptions: [{ value: 1, label: "ITEM A ITEM" }, { value: 2, label: "ITEM FIXO" }],
            selectCaractOption: []
        },
        isLoading: false
    }

    onBlurField = (value: any) => {
        this.setState({
            values: {
                ...this.state.values, ...value
            }
        })
        this.props.onChange({ MBM: { ...this.state.values, ...value } })
    }

    loadCaractByProduct(product_id: any, stateName: any) {
        if (product_id) {
            this.setState({ isLoading: true })
            axios.get(`api/v1/custom/flowserize/${product_id}/undefined/undefined`).then((res) => {
                let resultData = res?.data?.items
                resultData = _.map(resultData, (item) => {
                    return { value: item.characteristic_id, label: item?.characteristic_description }
                })

                this.setState({ [stateName]: { ...this.state[stateName], selectCaractOption: resultData } })
            }).finally(() => {
                this.setState({ isLoading: false })
            })
        }
    }

    componentDidMount(): void {
        if (this.state?.values?.params?.pedvenda?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.pedvenda?.defaultProduct, "pedvenda")
        }

        if (this.state?.values?.params?.stock?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.stock?.defaultProduct, "stock")
        }

        if (this.state?.values?.params?.stockbuy?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.stockbuy?.defaultProduct, "stockbuy")
        }
        if (this.state?.values?.params?.feedstock?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.feedstock?.defaultProduct, "feedstock")
        }
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        // Verifica se o valor de defaultProduct foi alterado
        if (prevState?.values?.params?.pedvenda?.defaultProduct !== this.state?.values?.params?.pedvenda?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.pedvenda?.defaultProduct, "pedvenda")
        }
        if (prevState?.values?.params?.stock?.defaultProduct !== this.state?.values?.params?.stock?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.stock?.defaultProduct, "stock")
        }
        if (prevState?.values?.params?.stockbuy?.defaultProduct !== this.state?.values?.params?.stockbuy?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.stockbuy?.defaultProduct, "stockbuy")
        }
        if (prevState?.values?.params?.feedstock?.defaultProduct !== this.state?.values?.params?.feedstock?.defaultProduct) {
            this.loadCaractByProduct(this.state?.values?.params?.feedstock?.defaultProduct, "feedstock")
        }
    }

    renderSwitchCase(option: any, paramName: any) {
        switch (option) {
            case 1:
                return (<>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Usar o item do FF fixo nesses itens? <a style={{ color: "red" }}>*</a> </label>
                            <Toggle checked={this.state?.values?.params?.[paramName]?.fixedItem} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, [paramName]: { ...this.state.values.params[paramName], fixedItem: value } } })} />
                        </b>
                    </div>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        {this.state?.values?.params?.[paramName]?.fixedItem ? (<>
                            Uma Tela de configuração DExPara será exibida na tela de importação.

                            {/* <b >
                                <label>Selecione o Item do FF a ser Utilizado <a style={{ color: "red" }}>*</a> </label>
                                <AxiosSelectPicker
                                    data={[]}
                                    api={{
                                        methd: 'GET',
                                        url: 'api/v1/products/select'
                                    }}
                                    defaultValue={this.state?.values?.params?.[paramName]?.defaultProduct}
                                    onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, [paramName]: { ...this.state.values.params[paramName], defaultProduct: value } } })}
                                    style={{ width: "100%" }}
                                    placeholder="Selecione..."
                                />
                            </b> */}

                        </>) : (<>
                            Todos os Produtos desse serviço serão importados.
                        </>)}
                    </div>
                </>)
                break;

            case 2:
                return (<>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Selecione o Item do FF a ser Utilizado <a style={{ color: "red" }}>*</a> </label>
                            <AxiosSelectPicker
                                data={[]}
                                api={{
                                    methd: 'GET',
                                    url: 'api/v1/products/select'
                                }}
                                value={this.state?.values?.params?.[paramName]?.defaultProduct}
                                defaultValue={this.state?.values?.params?.[paramName]?.defaultProduct}
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, [paramName]: { ...this.state.values.params[paramName], defaultProduct: value, caractItemSelected: null } } })}
                                style={{ width: "100%" }}
                                placeholder="Selecione..."
                            />
                        </b>
                    </div>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Importar Produtos MBM como Caracteristicas? <a style={{ color: "red" }}>*</a> </label>
                            <Toggle checked={this.state?.values?.params?.[paramName]?.caractItem} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, [paramName]: { ...this.state.values.params[paramName], caractItem: value } } })} />
                        </b>
                    </div>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        {this.state?.values?.params?.[paramName]?.caractItem && (<>
                            <AxiosSelectPicker
                                placeholder="Selecione..."
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, [paramName]: { ...this.state.values.params[paramName], caractItemSelected: value } } })}
                                data={this.state?.[paramName]?.selectCaractOption || []}
                                defaultValue={this.state?.values?.params?.[paramName]?.caractItemSelected}
                                isNumber={true}
                                value={this.state?.values?.params?.[paramName]?.caractItemSelected}

                            />

                            {/* <CheckPicker
                                placeholder="Selecione..."
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, [paramName]: { ...this.state.values.params[paramName], caractItemSelected: value } } })}
                                data={this.state?.[paramName]?.selectCaractOption || []}
                                defaultValue={this.state?.values?.params?.[paramName]?.caractItemSelected}
                            /> */}
                        </>)}
                    </div>
                </>)
                break;
        }
        return (<></>)
    }


    render(): React.ReactNode {
        const MBM = this.state.values

        return (<>
            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>MBM Endereço Remoto<a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_host} onChange={(value) => this.onBlurField({ db_host: value })}></Input>
                </div>
                <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>MBM Porta <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_port} onChange={(value) => this.onBlurField({ db_port: value })}></Input>
                </div>
                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>MBM Usuario BD <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_user} onChange={(value) => this.onBlurField({ db_user: value })}></Input>
                </div>
                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>MBM Senha BD <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_password} onChange={(value) => this.onBlurField({ db_password: value })} type="password"></Input>
                </div>
                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>MBM Nome do BD <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_name} onChange={(value) => this.onBlurField({ db_name: value })}></Input>
                </div>
                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>MBM Nome do BD (int) <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={MBM?.db_name_int} onChange={(value) => this.onBlurField({ db_name_int: value })}></Input>
                </div>
            </div>
            <Divider><b>Parâmetros</b></Divider>
            <Panel style={{ marginBottom: 12 }} bordered header={"Pedidos (Projetos)"} collapsible>
                {/* <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>Parâmetro Ativo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.pedvenda?.active} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, pedvenda: { ...this.state?.values?.params?.pedvenda, active: value } } })} />
                    </b>
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Como importar Produtos? <a style={{ color: "red" }}>*</a> </label>
                        </b>
                        <SelectPicker placeholder="Selecione..." defaultValue={MBM?.params?.pedvenda?.importTypeProduct} data={this.state.pedvenda.selectItemOptions} onChange={(value) => this.onBlurField({ params: { ...this.state.values.params, pedvenda: { ...this.state?.values?.params?.pedvenda, importTypeProduct: value } } })} />
                    </div>
                </div>
                {this.renderSwitchCase(MBM?.params?.pedvenda?.importTypeProduct, "pedvenda")}

                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b >
                        <label>Importa Cliente Fixo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.pedvenda?.fixedClient} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, pedvenda: { ...this.state?.values?.params?.pedvenda, fixedClient: value } } })} />
                        {this.state?.values?.params?.pedvenda?.fixedClient && (<>
                            <AxiosSelectPicker
                                data={[]}
                                api={{
                                    methd: 'GET',
                                    url: 'api/v1/people/select'
                                }}
                                value={this.state?.values?.params?.pedvenda?.defaultClient}
                                defaultValue={this.state?.values?.params?.pedvenda?.defaultClient}
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, pedvenda: { ...this.state?.values?.params?.pedvenda, defaultClient: value } } })}
                                style={{ width: "100%" }}
                                placeholder="Selecione..."
                            />
                        </>)}
                    </b>
                </div>
            </Panel>

            <Panel style={{ marginBottom: 12 }} bordered header={"Estoque (Projetos)"} collapsible>
                {/* <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>Parâmetro Ativo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.stock?.active} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, stock: { ...this.state?.values?.params?.stock, active: value } } })} />
                    </b>
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Como importar Produtos? <a style={{ color: "red" }}>*</a> </label>
                        </b>
                        <SelectPicker placeholder="Selecione..." defaultValue={MBM?.params?.stock?.importTypeProduct} data={this.state.stock.selectItemOptions} onChange={(value) => this.onBlurField({ params: { ...this.state.values.params, stock: { ...this.state?.values?.params?.stock, importTypeProduct: value } } })} />
                    </div>
                </div>
                {this.renderSwitchCase(MBM?.params?.stock?.importTypeProduct, "stock")}

                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b >
                        <label>Importa Cliente Fixo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.stock?.fixedClient} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, stock: { ...this.state?.values?.params?.stock, fixedClient: value } } })} />
                        {this.state?.values?.params?.stock?.fixedClient && (<>
                            <AxiosSelectPicker
                                data={[]}
                                api={{
                                    methd: 'GET',
                                    url: 'api/v1/people/select'
                                }}
                                value={this.state?.values?.params?.stock?.defaultClient}
                                defaultValue={this.state?.values?.params?.stock?.defaultClient}
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, stock: { ...this.state?.values?.params?.stock, defaultClient: value } } })}
                                style={{ width: "100%" }}
                                placeholder="Selecione..."
                            />
                        </>)}
                    </b>
                </div>
            </Panel>

            <Panel style={{ marginBottom: 12 }} bordered header={"Compra Estoque (Projetos)"} collapsible>
                {/* <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>Parâmetro Ativo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.pedvenda?.active} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, pedvenda: { ...this.state?.values?.params?.pedvenda, active: value } } })} />
                    </b>
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Como importar Produtos? <a style={{ color: "red" }}>*</a> </label>
                        </b>
                        <SelectPicker placeholder="Selecione..." defaultValue={MBM?.params?.stockbuy?.importTypeProduct} data={this.state.stockbuy.selectItemOptions} onChange={(value) => this.onBlurField({ params: { ...this.state.values.params, stockbuy: { ...this.state?.values?.params?.stockbuy, importTypeProduct: value } } })} />
                    </div>
                </div>
                {this.renderSwitchCase(MBM?.params?.stockbuy?.importTypeProduct, "stockbuy")}

                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b >
                        <label>Importa Cliente Fixo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.stockbuy?.fixedClient} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, stockbuy: { ...this.state?.values?.params?.stockbuy, fixedClient: value } } })} />
                        {this.state?.values?.params?.stockbuy?.fixedClient && (<>
                            <AxiosSelectPicker
                                data={[]}
                                api={{
                                    methd: 'GET',
                                    url: 'api/v1/people/select'
                                }}
                                value={this.state?.values?.params?.stockbuy?.defaultClient}
                                defaultValue={this.state?.values?.params?.stockbuy?.defaultClient}
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, stockbuy: { ...this.state?.values?.params?.stockbuy, defaultClient: value } } })}
                                style={{ width: "100%" }}
                                placeholder="Selecione..."
                            />
                        </>)}
                    </b>
                </div>
            </Panel>

            <Panel style={{ marginBottom: 12 }} bordered header={"Compra Materia-Prima (Projetos)"} collapsible>
                {/* <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b>
                        <label>Parâmetro Ativo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.feedstock?.active} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, feedstock: { ...this.state?.values?.params?.feedstock, active: value } } })} />
                    </b>
                </div> */}
                <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                    <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                        <b>
                            <label>Como importar Produtos? <a style={{ color: "red" }}>*</a> </label>
                        </b>
                        <SelectPicker placeholder="Selecione..." defaultValue={MBM?.params?.feedstock?.importTypeProduct} data={this.state.feedstock.selectItemOptions} onChange={(value) => this.onBlurField({ params: { ...this.state.values.params, feedstock: { ...this.state?.values?.params?.feedstock, importTypeProduct: value } } })} />
                    </div>
                </div>
                {this.renderSwitchCase(MBM?.params?.feedstock?.importTypeProduct, "feedstock")}

                <div style={{ width: "100%", display: "flex", marginBottom: 8, flexDirection: "column" }}>
                    <b >
                        <label>Importa Cliente Fixo? <a style={{ color: "red" }}>*</a> </label>
                        <Toggle checked={this.state?.values?.params?.feedstock?.fixedClient} onChange={(value, e) => this.onBlurField({ params: { ...this.state.values.params, feedstock: { ...this.state?.values?.params?.feedstock, fixedClient: value } } })} />
                        {this.state?.values?.params?.feedstock?.fixedClient && (<>
                            <AxiosSelectPicker
                                data={[]}
                                api={{
                                    methd: 'GET',
                                    url: 'api/v1/people/select'
                                }}
                                value={this.state?.values?.params?.feedstock?.defaultClient}
                                defaultValue={this.state?.values?.params?.feedstock?.defaultClient}
                                onChange={(value: any) => this.onBlurField({ params: { ...this.state.values.params, feedstock: { ...this.state?.values?.params?.feedstock, defaultClient: value } } })}
                                style={{ width: "100%" }}
                                placeholder="Selecione..."
                            />
                        </>)}
                    </b>
                </div>
            </Panel>
            {/* {JSON.stringify(this.state.values)} */}
            {/* {JSON.stringify(this.props.values)} */}
        </>)
    }
}